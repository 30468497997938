import { Avatar, Box, Checkbox, formatMoney } from '@stationkim/front-ui'
import { OderMenu } from '../components/orderMenu'
import { OrderActions } from '../../../listOrders/components/orderActions/orderActions'
import { StatusChip } from '../../statusChip/statusChip'
import { OrderType } from '../../orderType/orderType'
import { PendencyChip } from '../../pendencyChip/pendencyChip'
import { EStatusCompra } from '@/main/enums'

export const getInnerTableColumns = ({
  setOrderToDetail,
  selectedPurchaseOrders,
  purchaseId,
  setSelectedPurchaseOrders,
  isAllChecked,
}) => {
  const selectedOrders = selectedPurchaseOrders[purchaseId] || []
  return [
    {
      title: '',
      dataKey: 'check',
      key: 'check',
      resizable: false,
      width: 72,
      headerRenderer: ({ rowData }) => (
        <Checkbox
          style={{ paddingLeft: '32px' }}
          onClick={() => setSelectedPurchaseOrders(rowData, true)}
          checked={isAllChecked}
        />
      ),
      cellRenderer: ({ rowData }) => (
        <Checkbox
          style={{ paddingLeft: '32px' }}
          onClick={() => setSelectedPurchaseOrders(rowData)}
          checked={selectedOrders.some((order) => order.id === rowData.id)}
        />
      ),
      preventLink: true,
    },
    { title: 'Código do pedido', dataKey: 'orderNumber', key: 'orderNumber', width: 156 },
    { title: 'Operadora', dataKey: 'operator', key: 'operator' },
    {
      title: 'Tipo',
      dataKey: 'orderType',
      key: 'orderType',
      cellRenderer: ({ rowData }) => <OrderType type={rowData.orderType} />,
    },
    {
      title: 'Valor',
      dataKey: 'totalValue',
      key: 'totalValue',
      cellRenderer: ({ rowData }) => 'R$ ' + formatMoney(rowData.totalValue),
    },
    {
      title: 'Status',
      dataKey: 'orderStatus',
      key: 'orderStatus',
      cellRenderer: ({ rowData }) => {
        if (
          [
            EStatusCompra.Efetivando,
            EStatusCompra.ProcessandoFormaPagamento,
            EStatusCompra.AguardandoPagamento,
          ].includes(rowData.purchaseStatus)
        )
          return <StatusChip purchaseStatus={rowData.purchaseStatus} />
        return <StatusChip orderStatus={rowData.orderStatus} />
      },
    },
    {
      title: 'Pendência',
      dataKey: 'orderStatus',
      key: 'orderStatus',
      cellRenderer: ({ rowData }) => {
        return (
          <PendencyChip
            pendency={rowData.pendencyStatus}
            onClick={() => {
              setOrderToDetail(rowData)
            }}
          />
        )
      },
    },
    {
      title: 'Responsável',
      headerRenderer: () => <Box sx={{ textAlign: 'center' }}>Responsável</Box>,
      dataKey: 'responsibleUser',
      align: 'center',
      key: 'responsibleUser',
      cellRenderer: ({ rowData }) => {
        return rowData.userAttributed?.map((user) => {
          return (
            <Avatar
              key={user.id}
              name={String(user.name).toUpperCase()}
              src={user.image}
              sx={{
                height: '32px',
                width: '32px',
                fontSize: '14px',
              }}
            />
          )
        })
      },
    },
    //remove
    {
      title: '',
      dataKey: 'oldActions',
      key: 'oldActions',
      resizable: false,
      width: 64,
      preventLink: true,
      cellRenderer: ({ rowData }) => (
        <OrderActions
          order={{
            orderType: 4, //rowData.orderStatus, solicitar a adicao
            status: rowData.orderStatus,
            id: rowData.id,
            code: rowData.orderNumber,
            companyId: rowData.companyId,
            purchaseId: rowData.purchaseId,
          }}
        />
      ),
    },
    //remove up
    {
      title: '',
      dataKey: 'actions',
      key: 'actions',
      preventLink: true,
      resizable: false,
      width: 64,
      cellRenderer: ({ rowData }) => (
        <OderMenu items={[{ action: () => setOrderToDetail(rowData), title: 'Mais Informações' }]} />
      ),
    },
  ]
}
