import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { decode } from 'jsonwebtoken'
import api from '@/infra/api'
import _ from 'lodash'
import { ETipoPendencia } from '@/main/enums'
import { AccountPersistentStore } from '@/main/store'
import { IPurchaseOrdersResponse } from './useOrderList'
import { getTempOrderPendencyResolutionDateString } from '../utils/getTempOrderPendencyResolutionDateString'

interface IResolvePendencyPayload {
  purchaseId: string
  orderId: string
  resolved: boolean
  pendencyType: ETipoPendencia
}

interface IUpdateQueryCacheParams extends IResolvePendencyPayload {
  queryClient: ReturnType<typeof useQueryClient>
  resaleId: string
}

const updateQueryCache = ({
  queryClient,
  resaleId,
  purchaseId,
  orderId,
  pendencyType,
  resolved,
}: IUpdateQueryCacheParams) => {
  try {
    const queryKey = queryClient.getQueriesData({ queryKey: ['purchases', resaleId] })?.[0]?.[0]
    queryClient.setQueryData(queryKey, (oldData: any) => {
      const newData: {
        pages: AxiosResponse<IPurchaseOrdersResponse>[]
        pageParams: any[]
      } = _.cloneDeep(oldData)

      const page = newData.pages.find((page) =>
        page.data.valor.compras.some((purchase) => String(purchase.idCompra) === String(purchaseId)),
      )
      if (!page) return oldData

      const purchase = page.data.valor.compras.find((p) => String(p.idCompra) === String(purchaseId))
      const order = purchase?.pedidos.find((o) => String(o.idPedido) === String(orderId))
      const pendency = order?.pendencias.find((p) => String(p.tipoPendencia) === String(pendencyType))
      if (pendency) {
        pendency.resolvido = resolved
        pendency.data = getTempOrderPendencyResolutionDateString()
      }

      return newData
    })
  } catch (error) {
    throw new Error('Fail updating list')
  }
}

export const useResolvePendency = () => {
  const queryClient = useQueryClient()
  const account = useAtomValue(AccountPersistentStore)
  const resaleId = decode(account.auth.accessToken)?.resaleId

  const resolvePendency = useMutation({
    mutationKey: ['brokerOrderResolvePendency'],
    mutationFn: (payload: IResolvePendencyPayload) =>
      api.patch('/revenda-pedidos-bff/pedido/atualizarPendencia', {
        idPedido: payload.orderId,
        resolvido: payload.resolved,
        tipoPendencia: payload.pendencyType,
      }),
    onSuccess: (_data, payload) => {
      updateQueryCache({ queryClient, resaleId, ...payload })
    },
  })

  return { resolvePendency }
}
