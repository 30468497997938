import { useQuery } from '@tanstack/react-query'
import api from '@/infra/api'
import { downloadFile } from '@/main/utils/functions'
import { useEffect, useState } from 'react'

export const useOrdersFiles = ({ orderId, orderNumber }: { orderId: string; orderNumber: string | number }) => {
  const {
    data,
    refetch: getBrokerOrderLayout,
    isFetching: isLoadingOrderLayout,
  } = useQuery({
    queryKey: ['brokerOrderLayout', orderId],
    queryFn: () => api.get(`/revenda-pedidos-bff/pedido/gerararquivo/${orderId}`),
    enabled: false,
  })

  const [shouldDownload, setShouldDownload] = useState({ orderLayout: false })

  const updateDownloadOption = (file: string, value: boolean) =>
    setShouldDownload((state) => ({ ...state, [file]: value }))

  useEffect(() => {
    const res = data?.data?.valor?.base64Result
    if (res && shouldDownload.orderLayout) {
      downloadFile({
        download: `Layout do Pedido ${orderNumber}.txt`,
        href: `data:text/plain;base64,${res}`,
      })
      updateDownloadOption('orderLayout', false)
    }
  }, [data, orderNumber, shouldDownload])

  return {
    getBrokerOrderLayout: () => {
      getBrokerOrderLayout()
      updateDownloadOption('orderLayout', true)
    },
    isLoadingOrderLayout,
  }
}
